
            @import "@/assets/css/vars.scss";
          
























































































































































































































































































































































































































@import '../../assets/css/buttonStyle.scss';
.config{
   
    .wrapp:nth-of-type(odd){
        background-color: rgba(0, 0, 0, 0.2);
    }
    .wrapp{
        display: flex;
        width:100%;

        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        // padding: 5px;
        .div{
            max-width: 100%;
        }
        .wrapInput{
            display: flex;
            // flex-wrap: wrap;
            align-items: center;
            width: 831px;
            max-width: 100%;
            // overflow: auto;
           
            .title{
                width: 250px;

            }
            textarea,
            input{
                margin: 5px 0;
                height: 39.48px;
                border: 1px solid var(--border-color);
                // filter: drop-shadow(0px 1px 3px #DFDFE8);
                border-radius: 6px;
                outline: none;
                width: 550px;
                // width:68%;
                background: transparent;
                color: var(--text-color);

            }
            textarea {
                height: 150px;
            }
            .inputPicker{
                width: calc(550px - 40px);
            }
            .selectPicker {
                height: 39.48px;
                width: 39.48px;
                border: 1px solid var(--border-color);
                border-radius: 6px;
                position: relative;
                .fill{
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
                .picker{
                    position:absolute;
                    top:36px;
                    right: 40px;
                    z-index:1;
                }
            }

        }
       
        .image{
            margin-right: auto;
            width: 831px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .info{
            margin-left: 10px;
            // margin-bottom: 10px;
            position: relative;
            width:fit-content;
        }

    }
    .wrappButton{
        background-color: var(--main-background-color);
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 50px ;
        position: sticky;
        bottom: 0;
        z-index: 2;

        .buttonSend,
        .buttonCancel {
            width:40%;
        }
        
    }

}
@media only screen and (max-width:1100px){
    .config{
        .wrapInput{
            // flex-direction:column;
            flex-wrap: wrap;
            height: fit-content;
            padding-top: 10px;
            padding-bottom: 5px;
            
        }
        .title{
            order:1
        }
        .info{
            margin-top: 3px;
            order: 2;
        }
        textarea,
            input{
                order:3;
            }
    }
}
