
            @import "@/assets/css/vars.scss";
          








































  .go-top-wrapper {
    // position: absolute;
    // bottom: 80px;
    // right: 30px;
     z-index: 2000; // set paretn z-index to not limit the z-index of the child
     position:relative;

     
     
  
    @keyframes example {
      0%   {margin-bottom: 0px}
      50% {margin-bottom: 10px}
      100% {margin-bottom: 0px}
    }
   
    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 var(--btn-color);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }
    .wrapImgTop{
      height: 42px;
      width: 42px;
      border-radius: 50%;
      position: fixed;
      bottom: 80px;
      // right: 30px;
      // right: 20px;
      right: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

    box-shadow: 0 0 0 0 var(--btn-color);
	transform: scale(1);
	animation: pulse 2s infinite;
    background: var(--btn-color);
    // animation-timing-function: ease-in-out;
    //   opacity: .5;

    }
    .img {
      background: var(--btn-color);
      cursor: pointer;
    //   animation-name: example;
    //   animation-duration: 1s;
    //   animation-iteration-count: infinite;
    //   animation-timing-function: ease-in-out;

    // position: fixed;
    //   bottom: 80px;
    //   right: 10px;
    
      height: 42px;
      width: 42px;
     border-radius: 50%;
      z-index: 1001;
     
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        // width: 38px;
        // height: 38px;
      }
    }
    .img:hover {
      // background: var(--btn-hover);
      filter: brightness(0.96);
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
      transition-duration: .9s;
      z-index: 1001;
    }
    // .img.hover:hover {
    //   opacity: 1;
    // }
  }
  @media only screen and (max-width: 768px){
    .go-top-wrapper {
      // .img {
      //   height: 50px;
      //   width: 50px;
      // }
      // transform: scale(0.9);
    }
  }
  