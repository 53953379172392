
            @import "@/assets/css/vars.scss";
          








































































.wrappComments{
  .option{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    color: var(--text-lighter);
    margin-bottom: 30px;
    cursor: pointer;
  }
    
}

