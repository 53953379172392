
            @import "@/assets/css/vars.scss";
          


























































































































































































.single-blog-details-wrapper {
  display: flex;
  flex-direction: column;
  .line {
    width: 30px;
    height: 2px;
    background: var(--border-color);
    margin-bottom: 15px;
  }
  .wrapp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .left {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    justify-content: space-between;
    // margin-bottom: 22px;
    width: 50%;
    min-width: 300px;
    border-top: 1px solid var(--border-color);
    padding: 10px 0px;
    padding-top: 12px;
    text-align: left;
    .date {
      font-size: 15px;
      line-height: 22px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 25px;
      color: var(--text-soft-color);
      // margin-bottom: 5px;
    }
    .time {
      font-size: 13px;
      line-height: 22px;

      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 25px;
      color: var(--text-soft-color);
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;
    min-width: 205px;
    .written-by {
      font-size: 13px;
      line-height: 24px;
      color: var(--text-soft-color);
    }
    .author {
      font-size: 15px;
      line-height: 24px;
      color: var(--text-soft-color);
    }
  }
  .share-wrapper {
    width: 50%;
    border-top: 1px solid var(--border-color);
    padding: 10px 0px;
    min-width: 300px;
    text-align: left;
    .share {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 25px;
      color: var(--text-color);
    }
    .share-icons {
      margin-top: 10px;
      img {
        cursor: pointer;
        margin-right: 30px;
        max-height: 12px;
        max-width: 12px;
      }
      img:last-child {
        margin-right: 0px;
      }
    }
  }
  .claps{
     box-sizing: border-box;
     border-top: 1px solid var(--border-color);
     width: 300px;
     height: 71px;
     padding: 10px 0;
     padding-top: 20px;
     display: flex;
     align-items: center;

     .showClaps{
       margin-left: 25px;
     }

  }
}
@media only screen and (max-width: 1200px){
  .single-blog-details-wrapper {
    .left, .share-wrapper {
      width: 100%;
      
    }
    .claps{
        width: 100%;
    }
  }
}
@media only screen and (max-width: 450px){
  .single-blog-details-wrapper {
    .left, .share-wrapper {
      width: 100%;
      min-width: 1px;
    }
  }
}
