
            @import "@/assets/css/vars.scss";
          




























































































































































































































































.wrapProfilwData {
    display:flex;
    text-align: left;
    // background-color: var(--main-background-color);
    .data {
        width: 406px;
        margin-right: 127px;
        .titleProfile {
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 50px;

            display: flex;
            align-items: center;
            color: var(--title-color);
        }
        .mailProfile {
            height: 24px;
            // left: 321px;
            // top: calc(50% - 24px/2 - 363.5px);

            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;

            display: flex;
            align-items: center;
            color: var(--text-color);

            margin-top: 6px;
            margin-bottom: 35px;

        }
        .label {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            display: flex;
            align-items: center;

            color: var(--text-color);

            
        }
        .round {
            position: relative;
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            // margin-bottom: 21px;
             margin-bottom: 20px;
            .input {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                background: var(--main-background-color);
                border: 1px solid var(--border-color);
                // filter: drop-shadow(0px 1px 3px var(--border-color));
                border-radius: 5px;
                outline: none;
                padding-left: 10px;

                // text 
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;

                color: var(--text-color);

            }
            .input:focus{
                background: var(--main-background-color);
                // border: 1px solid var(--text-lighter);
                filter: drop-shadow(0px 1px 3px var(--border-color));
                box-sizing: border-box;
                border-radius: 5px;
                color: var(--text-color);
            }
            .input:read-only{
                border: 1px solid var(--border-color);
                color: var(--text-soft-color);
                filter: none;
            }
            .inputErr{
                border: 1px solid var(--err-color);;
            }
            .editTxt{
                position: absolute;
                right:0;
                //top:50%;
                bottom: calc(50% - 10px);
                padding-right: 10px;
                max-height: 100%;
                cursor: pointer;
                //text
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: right;
                letter-spacing: 0.02em;
                // text-transform: uppercase;
                color: var(--text-soft-color);
                img{
                   height: 17px !important;
                   width: auto !important;
                //    opacity: 0.2;
                }
            }
            .cancelTxt{
                position: absolute;
                right:0;
                top:-35%;
                //bottom: calc(50% - 10px);
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: right;
                letter-spacing: 0.02em;
                color: var(--text-soft-color);
                cursor: pointer;
            }
            .cancelTxt:hover {
                filter: brightness(0.9);
            }
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            //   border: 1px solid green;
            -webkit-text-fill-color: var(--text-soft-color);
            -webkit-box-shadow: 0 0 0px 1000px var(--main-background-color) inset;
            transition: background-color 5000s ease-in-out 0s;
            // background-color: var(--main-background-color) !important;
        }
        

        .roundButton{
            margin-top:40px;
            margin-bottom: 42px;
            display: flex;
            align-items: center;
            .button {
                box-sizing: border-box;
                height: 50px;
                border-radius: 52px;
                background-color: var(--btn-color);
               
                padding: 13px 40px;
                color: var(--main-background-color);
                cursor: pointer;

                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;

                display: flex;
                align-items: center;
                text-align: center;
                margin-right: 20px;
            }
            .disabled {
                opacity: .4;
                filter: brightness(2);
                cursor: initial;

            }
            .noChanges{
                text-transform: capitalize;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                // display: flex;
                // align-items: center;
                // text-align: center;

                color: var(--text-soft-color);
            }
        }
    }
    .containerImg {
       
    }


}
.bold{
    cursor: pointer;
    font-weight: bold;
    text-transform: initial;
}
.msg{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: var(--btn-color);

}
.msgErr{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: var(--err-color);

}


// .wrapProfilwData {
//     .data {
//         .titleProfile {}
//         .mailProfile {}
//         .label {}
//         .round {
//             .input {}
//             .editTxt{}
//         }
//         .roundButton{
//             .button {   
//             }
//             .disabled {
//             }
//             .noChanges{  
//             }
//         }
//     }
//     .containerImg {
    
//         .wrappImg {
              
//         }
//     }
// }

@media only screen and (max-width: 767px){
     .wrapProfilwData {
      
      .data {
        width: 404px;
        margin-right: 79px;
        .titleProfile {
            font-size: 30px;
            line-height: 40px;
        }
        .mailProfile {}
        .label {}
        .round {
           margin-bottom: 22px; 
            .input {}
            .editTxt{}
        }
    }

}
}
@media only screen and (max-width: 750px){
     .wrapProfilwData {
        flex-direction: column-reverse;
        margin-bottom: 73px;
        width: 100%;
        

        .data {
            width: 343px;
            margin-right: 79px;
            .label {}
            .round {
            margin-bottom: 21px; 
                .input {}
                .editTxt{}
            }
            .roundButton{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 0;
                .button {   
                    margin-bottom: 21px;
                    // min-width:120px;
                    height: 48px;
                    border-radius: 52px;
                    display: flex;
                    justify-content: center;
                    //padding-left: 0;

                }
                .disabled {
                    margin-bottom: 21px;
                }
                .noChanges{  
                }
            }

            
        }

        .containerImg {
            margin-bottom: 43px;
            .titleProfile {
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 50px;
                /* identical to box height, or 179% */


                color: #000000;

            }
            .mailProfile {
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;

                margin-bottom:32px;

            }
            .wrappImg {
                
            }
        }

    }

    .msg{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        // display: flex;
        // align-items: center;

        /* Orange primary */

        color: var(--btn-color);

        margin-bottom: 23px;


    }

}
@media only screen and (max-width: 500px){
     .wrapProfilwData {
        //  padding-bottom:90px;
        .noChanges{
            margin-bottom: 90px;
        }
     }
}

