
            @import "@/assets/css/vars.scss";
          



















.success{
     width: 406px;
    .title{
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        /* identical to box height, or 143% */
        text-align: center;

        /* black */

        color: var(--title-color);
        margin-bottom:44px;

    }
    .txt{
       
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--text-color);
    }

}
@media only screen and (max-width: 500px){
    .success{
         max-width: 335px;
         margin:0 20px;
        .title{
            font-size: 28px;
            line-height: 30px;
            margin-bottom: 39px;
        }
        .txt{
            font-size: 15px;
            line-height: 24px;
        }

    }

}
