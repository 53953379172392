
            @import "@/assets/css/vars.scss";
          



















































































































































































@import '../../assets/css/scrollbarStyles.scss';

.dropdown-wrapper {
  position: relative;
  background: var(--main-background-color);
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  // width: max-content;

  .header-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      // border: 1px solid #f2f2f2;
      cursor: pointer;
      // height: 39.48px;
      border: 1px solid var(--border-color);
      // filter: drop-shadow(0px 1px 3px var(--border-color));
      border-radius: 6px;
  
    span {
      margin-right: 20px;
      text-align: left;
    }
    img {
      transition-duration: 0.3s;
    }
    img.open {
      transform: rotate(180deg);
    }
  }
  .header-wrapper.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .dropdown {
   
      position: absolute;
      top: calc(100% + 3px);
      background-color: var(--main-background-color);
      left: 1px;
      min-width: calc(100% - 2px);
       //box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
      border: 1px solid var(--border-color);
      // filter: drop-shadow(0px 1px 3px var(--border-color));
      z-index: 100;

    .option-wrapper {
      position: relative;
      max-height: 200px;
      max-width: 100%;
      overflow-x: hidden;
      // max-width: 80%;
      overflow: auto;
      @import '@/assets/css/scrollbarStyles.scss';
     
     

      .option {
        white-space: nowrap;
        height: 38px;
        line-height: 38px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        display: block;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
        background-color: var(--main-background-color);
      }
      .option.active {
        // background: rgba(196, 196, 196, 0.15);
        // opacity: 0.07;
        filter:  brightness(1.55);
      }
      .clear{
        color: var(--btn-color);
        text-align: center;
        height: 28px;
      }
      input {
        border: none;
        width: 100%;
        padding: 0px;
        padding-left: 10px;
        box-sizing: border-box;
        height: 38px;
        background: var(--main-background-color);
        color: var(--text-color);
      }
      input:focus {
        outline: none;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .dropdown-wrapper {
    font-size: 0.9em;
    .dropdown {
      position: absolute;
      top: calc(100% + 3px);
      // background-color: white !important;
      left: 1px;
      //min-width: calc( 100% - 2px );
      min-width: 50%;
      width: 100%;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
      z-index: 1;

      .option-wrapper {
        position: relative;
        max-height: 200px;
        max-width: 100%;
        overflow: auto;
        @import '@/assets/css/scrollbarStyles.scss';
       

        .option {
          white-space: wrap;
          min-height: 38px;
          max-width: 100%;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 10px;
          padding-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
