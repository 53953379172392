
            @import "@/assets/css/vars.scss";
          





























.baner {
    // width: 300px;
    // height: 600px;

    //background: #050807;
    width: 230px;
    height: 345px;
    margin-top: 25px;
    // position: sticky;
    // top: 25px;

    .holdContent {
        // background-color: gold;
        // padding:25px;
         background: #050807;
         border-radius: 5px;
         margin:0 auto;
         width:100%;
         box-sizing: border-box;
         height: 100%;
         position:relative;
         overflow: hidden;
    //  background-image: url("../../assets/images/baner.png");
    //  background-size: cover;
    //  background-position: center center;
        img{
            position: absolute;
            top: 0;
            left: 0;
            width:100%;
        }

       

         .button{
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            width: 245px;
            height: 50px;
            border-radius: 35px;
            background: rgb(255, 255, 255);
            // background: linear-gradient(150.58deg, #000000 1.1%, rgba(0, 0, 0, 0) 42.66%);

            // font
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: 0.01em;
            color: var(--green-darker);



         }
    }
}
@media only screen and (max-width: 1080px){// 1280px
    .baner{
        width: initial;
        height: 355px ;
        padding-bottom: 40px;
         margin-left: 0px;
        .holdContent{
            box-sizing: border-box;
            margin:0 auto;
            //width:1278px;
            width: calc(100% - 58px );
            margin: 0 29px;
            height: 100%;
            // padding:35px 30px;
            //background-image: url("../../assets/images/back2.svg");
            // background-repeat: no-repeat;
            // background-position: center center;
            //background-attachment: fixed;
            background-size: cover;
            img{
                position: initial !important;
                object-fit: cover;
                object-position: center center;
               
            }

            .widgetTitle{
                .big {
                    display: none;
                }
                .medium {
                    display: initial;
                }
                .small{
                    display: none;
                }
            }
            .widgetTxt {
                .small{
                    display: none;
                 }
                .big {
                     display: none;
                }
            }
        }
    }
}
@media only screen and (max-width: 800px){ // 768px
    .widgetBlack{
        width: initial;
        height: 355px ;
        .holdContent{
            box-sizing: border-box;
            // width: 100%;
            .widgetTitle{
                .big {
                    display: none;
                }
                .medium {
                    display: initial;
                }
                .small{
                    display: none;
                }
            }

            .widgetTxt{
                 padding:12px 0 74px 0;
                 .small{
                    display: none;
                 }
                 .big {
                     display: none;
                }
            }

            .button{}
        }
    }
}
@media only screen and (max-width: 620px){ //375
 .widgetBlack{
        height: 380;
        min-height: 380px;
        padding-bottom: 0px;
        .holdContent{
            // padding-top: 47px;
            // padding-bottom: 39px;
            border-radius: initial;
            width: 100%;
            margin:0 auto;
            height: 100%;
            // padding:0 16px 0 16px; // hold content on all site
            padding:26px 58px 16px 30px;
            background-position: 70% 50%;

            .widgetTitle{
               
                font-style: normal;
                font-weight: 900;
                font-size: 48px;
                line-height: 50px;

                .big {
                    display: none;
                }
                .medium {
                    display: none;
                }
                .small{
                    display: initial;
                }
            }

            .widgetTxt{
                 padding:12px 0 44px 0;
                 .small{
                     display: initial;
                 }
                 .big{
                     display: none;
                 }
            }

            .button{
                width: 150px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 26px;
                /* identical to box height, or 186% */

                display: flex;
                align-items: center;
                letter-spacing: 0.01em;

                /* black */

                color: var(--green-darker);
            }

        }
    }
}
@media only screen and (max-width: 368px){ //375
 .baner{
        height: initial;
        min-height: 380px;
        padding-bottom: 0px;
 }
}

