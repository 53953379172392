
            @import "@/assets/css/vars.scss";
          














































.wrappIcon {
    // transform: scale(0.3);
    div{
        margin-left: 5px;
    }


}

