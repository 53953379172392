
            @import "@/assets/css/vars.scss";
          

























































































































































.picker-wrapper {
    // background-color: var(--green-color);
  height: 100%;
  display: flex;
  position: relative;

  .flag {
    height: 19px;
    width: 26px;
    margin-right: 5px;
  }

  .dropdown-wrapper {
    //   background-color: var(--green-color);
    padding: 20px;
    padding-top: 40px;
    padding-right: 0px;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 3;
  }
  .dropdown {
    background-color: white;
    padding: 10px;
    right: 0px;
    top: 100%;
    // border: 1px solid #ddd;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;

    .locale-option {
      display: flex;
      align-items: center;
      cursor: pointer;

      .picker-title {
        // margin-top: 5px;
         margin-right: 0px;
         color: var(--green-color);
      }
    }
    .locale-option:hover .picker-title {
            color: var(--green-color);
    //   color: $text-hover-color;
    }
  }
}
.picker {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .height-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.picker-flag span {
  border-radius: 3px;
  margin-right: 5px;
}
.selectTxt{
  width: fit-content;
  color: var(--green-text);
}
.darkcolor{
  color: var(--green-text);
}
.picker-title {
  margin-right: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  transition-duration: 0.2s;
  color: var(--green-text);
  width: fit-content;
}
.picker:hover .picker-title {
//   color: $text-hover-color;
}
.small-nav-wrapper {
  .picker-wrapper {
    margin-bottom: 200px;
  }
  .dropdown-wrapper {
    padding: 0px;
    position: absolute;
    top: 50px;
    left: 0px;
    z-index: 3;
    width: 85px;
  }
  .dropdown-arrow {
  //   filter: brightness(5);
  transition-duration: 0.3s;
  max-width: 10px;
  }
  img {
      transition-duration: 0.3s;
    }
    img.open,
    .dropdown-arrow.open {
      transform: rotate(180deg);
    }
   
  .height-wrapper .picker-title {
  //   color: white;
    color: var(--green-text);
  }
}
.picker-wrapper.blackDiv {
  margin-bottom: 0 !important;
  min-height: 120px !important;
  // background-color: #3f3f41;
  // color: #ffffff;
  color: var(--green-text);
  .dropdown-wrapper{
    top: 30px;
    left: 0px;
  }
   .dropdown {
    background-color: white;
    background-color: #3f3f41;

    padding: 0px;
    right: 0px;
    top: 10%;
    border: transparent;
    .picker-title{
       color: #ffffff !important;
    }
   }

}
