
            @import "@/assets/css/vars.scss";
          













































.loginForgot{
    display: flex;
    // width: 100vw;
    height: calc(100vh - 80px);
    justify-content: center;
    // align-items: center;
    padding-top: 87px;
    
}
@media only screen and (max-width: 500px){
    .loginForgot{
        height: initial;
    }
}
