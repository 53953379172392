
            @import "@/assets/css/vars.scss";
          
























































































.image {
    margin-right: auto;
    width: 831px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    .title {
        width: 150px;

    }
    .wrapIn{
        width: 551px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    img {
        height: 70px;
        width: auto;
        max-width: 70%;
        overflow: hidden;
        margin: 10px 0;
        margin-right: 50px;

    }
    input {
        width: 185px;
        text-align: right;
    }


}
@media only screen and (max-width:1100px){
    .image{
        flex-wrap: wrap;
        height: fit-content;

    }
}

