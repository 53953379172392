@import '@/assets/css/buttonStyle.scss';

.create-blog-wrapper {
    padding-bottom: 30px;
    text-align: left;
    width: 50%;
    margin-left: 5%;

    .no-selection {
        .show-more-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 40px;
            margin-bottom: 120px;
            max-width: 735px;

            .show-more {
                cursor: pointer;
                font-size: 15px;
                line-height: 24px;
                color: var(--text-soft-color);
            }
        }
    }

    .current-image-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .remove {
            cursor: pointer;
            margin-left: 20px;
        }

        .current-image {
            max-height: 150px;
            width: auto;
        }
    }

    .return {
        margin-bottom: 30px;
        margin-left: auto;
        cursor: pointer;
        min-width: 50px;
        width: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .circle {
            background-color: var(--btn-color);
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        .circle:hover {
            filter: brightness(0.90);
        }

    }

    .btn-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        position: sticky;
        bottom: 0;
        background: var(--main-background-color) !important;
        border: 3px solid var(--main-background-color);
        border-top: 3px solid var(--border-color);
        padding: 30px 0;

        .btn:first-child {
            margin-right: 20px;
        }
    }

    // .btn {
    //     height: 50px;
    //     width: 170px;
    //     line-height: 52px;
    //     color: white;
    //     text-align: center;
    //     background: var(--main-color);
    //     border-radius: 52px;
    //     cursor: pointer;
    //     transition-duration: .3s;
    //     margin: 20px 0;
    // }

    // .btn.reset {
    //     background: white;
    //     border: 1px solid var(--main-color);
    //     color: var(--main-color);
    // }

    // .btn:hover {
    //     // background: --btn-hover;
    //     filter: brightness(0.90);
    //     // filter: drop-shadow(0px 1px 3px #DFDFE8);
    // }

    // .btn.reset {
    //     background: white;
    //     border: 1px solid var(--main-color);
    //     color: var(--main-color);
    // }

    .input-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        background: var(--main-background-color);

        .title {
            margin-bottom: 5px;
            font-weight: 600;
            font-size: 12px;
            line-height: 13px;
            color: var(--text-color);
        }

        input {
            font-size: 12px;
        }
    }

    .input-wrapper>input[type="text"],
    .input-wrapper>input[type="date"],
    .input-wrapper>input[type="number"] {

        height: 39.48px;
        border: 1px solid var(--border-color);
        // filter: drop-shadow(0px 1px 3px var(--border-color));
        border-radius: 6px;
        outline: none;
        background: var(--main-background-color);
        color: var(--text-color);

    }

    .create {

        .title {
            cursor: pointer;
        }

    }

    .flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 30px;
        background-color: var(--main-background-color);
    }

    .preview {
        margin-top: 30px;
        margin-bottom: 30px;

        .content {
            box-sizing: border-box;
            border: 1px solid var(--border-color);
            padding: 10px;
            width: 100%;
            overflow-y: hidden;

            img,
            p img,
            span img,
            h1 img,
            h2 img,
            h3 img,
            h4 img,
            h5 img,
            h6 img {
                max-width: 100%;
                height: auto;
            }
        }
    }


}

img {
    max-width: 100% !important;
    height: auto;
}

.duplicate {
    margin-top: 20px;
}

.localePickerAdmin {
    background: var(--main-background-color);
    width: 65px;
    height: 30px;

    .picker-wrapper {
        height: fit-content;
        display: flex;
        justify-items: flex-start;
        align-items: flex-start;
        background: var(--main-background-color);

    }
}