
            @import "@/assets/css/vars.scss";
          
























































































































.mainComment{
        //  max-width:100%;
        //  width: fit-content;
        width:100%;
        min-height:100px;
        padding: 10px;
        box-sizing: border-box;
        background-color: var(--comments-background-color);
        // background: #ECECEC;
        border-radius: 25px;
        padding: 21px 17px;
        transition-duration: height .3s;

       .wrappMaindata{
          display: flex;
          justify-content: space-between;
          margin-bottom: 3px;
          font-weight: bold;

          .name{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 17px;
            color: var(--title-color);
          }
          .date{
            margin-left: 30px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            /* identical to box height, or 167% */

            letter-spacing: 0.04em;
            color: var(--text-soft-color);

          }
       }

      }
       .more{
        margin-left: 13px;
        border-left: 1px solid var(--comments-background-color);
        background-color: var(--main-background-color);
        

        .option{
          padding-left: 10px;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: var(--text-lighter);
          margin-bottom: 5px;
          cursor: pointer;
        }
        .option2{
          display: flex;
          // justify-content: center;
          align-items: flex-end;
          margin-bottom: 20px;
          .option{
            margin-bottom: 0;
            padding-left: 22px;
          }
          .line{
            box-sizing: border-box;
            width: 50px;
            height: 0;
            border-top: 1px solid var(--comments-background-color);
          }

        }
        .option:first-of-type{
          margin-bottom: 2px;
        }
         .wrappSubComm{
            display: flex;
            flex-direction: column;
           transition: height .3s;
            // padding-left: 50px;
            //  justify-content: flex-end;
            //  align-items: flex-end;
         }
       }
       .more2{
        border-left: 1px solid transparent;
        background-color: var(--main-background-color);
        // height: 237px ;
        // transition: height .3s;
        overflow: hidden;
        // 50px->line, 22px->padding from line to content, 13px->margin of more 
        margin-left: calc(50px + 22px + 13px);
       }


