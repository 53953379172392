// for modal scroll
.modal-wrapper::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}

.modal-wrapper::-webkit-scrollbar-track {
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.32);
}

.modal-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--btn-color);
  // outline: 1px solid slategrey;
  border-radius: 3px;
  ;
}

.modal-wrapper {
  scrollbar-color: var(--btn-color) rgba(0, 0, 0, 0.32);
  scrollbar-width: thin;
}

// for categories scroll:
.categories::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}

.categories::-webkit-scrollbar-track {
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.32);
}

.categories::-webkit-scrollbar-thumb {
  background-color: var(--btn-color);
  // outline: 1px solid slategrey;
  border-radius: 3px;
  ;
}

.categories {
  scrollbar-color: var(--btn-color) rgba(0, 0, 0, 0.32);
  scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  background: transparent;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.32);
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--btn-color);
  // outline: 1px solid slategrey;
  border-radius: 3px;
  ;
}

.scrollbar {
  scrollbar-color: var(--btn-color) rgba(0, 0, 0, 0.32);
  scrollbar-width: thin;
}

// styles for element that imported scrollbarStyles:
@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border: 1px solid white;
    background-color: rgba(0, 0, 0, 0.32);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--btn-color);
    // outline: 1px solid slategrey;
    border-radius: 3px;
    ;
  }

  & {
    scrollbar-color: var(--btn-color) rgba(0, 0, 0, 0.32);
    scrollbar-width: thin;
  }
}