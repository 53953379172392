
            @import "@/assets/css/vars.scss";
          























































.progresBar{
    width: 100vw;
    height: 5px;
    background-color: transparent;
    z-index:9000;
    position: fixed;
    top:0;

}
.progress{
    box-sizing: border-box;
    height: 100%;
    width:0;
    background-color:var(--btn-color);
}


