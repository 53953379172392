
            @import "@/assets/css/vars.scss";
          













































































@import '../../assets/css/buttonStyle.scss';
.home-blog-wrapper {
  width: calc(100% - 32px);
  background: var(--main-background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  // padding-top: 20px;
  // padding-bottom: 100px;
  // padding-bottom: 130px;
  padding-bottom: 90px;

  a {
    text-decoration: none;
  }

  .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

  }

  .home-blog-title {
    // font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 1278px;
    // height: 52px;
    // margin-bottom: 35px;
    // margin-top: 20px;
    margin-bottom: 43px;
    margin-top: 29px;

    span {
      // font-size: 13px;
      font-size: 15px;
      // line-height: 35px;
      line-height: 36px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: var(--title-color);
    }

    // .view-all-btn {
    //   position: absolute;
    //   right: 0px;
    //   width: 168px;
    //   height: 52px;
    //   line-height: 52px;
    //   border: 1px solid #4C4C52;
    //   box-sizing: border-box;
    //   border-radius: 52px;
    //   text-align: center;
    //   background: white;
    //   cursor: pointer;
    // }
  }
  .blog-title {
    height: 70px;
    width: 100%;
    // border-top: 1px solid var(--text-lighter);
    padding-top: 10px;
    box-sizing: border-box;
    margin-bottom: 25px;
    

    span {
      // font-size: 13px;
      // line-height: 36px;
      // letter-spacing: 0.02em;
      // text-transform: uppercase;
      // color: #9498A0;
      cursor: pointer;
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 60px;
      color: var(--title-color);
    }
  }
  .content-wrapper {
    max-width: 1278px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.blogPage.home-blog-wrapper {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  // padding-top: 40px;
}
@media only screen and (max-width: 768px){
  .home-blog-wrapper {
     padding-bottom: 121px;
    .btn-wrapper {
      display: block;
      // margin-left: 28px;
      // margin-left: 30px;
    }
    .home-blog-title {
      margin-top: 27px;
       margin-bottom: 24px;
      .view-all-btn {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 450px){
  .home-blog-wrapper {
    padding-bottom: 0px;
    padding-bottom: 81px;
     .home-blog-title {
       height: fit-content;
        margin-bottom: 26px;
        margin-top: 17px;
     }
  }
}
