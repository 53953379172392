
            @import "@/assets/css/vars.scss";
          





































































 @import '@/assets/css/singUp.scss';
    .enterEmail{
        width:406px;
        text-align: center;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
        .title{
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            text-align: center;

            color: var(--title-color);
        }
        .txt {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: var(--text-color);
            margin-top: 44px;
            margin-bottom: 24px;
        }
        .sendButton {
            cursor: pointer;
            // margin-top: 30px;
            margin-top: 15px;
            width: 366px;
            height: 50px;
            border-radius: 55px;

            background: var(--btn-color);
            border-radius: 55px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            /* White */

            color: var(--main-background-color);

        }
        .sendButton:hover{
            filter: brightness(.90);
        }

    }
@media only screen and (max-width: 500px){
    .enterEmail{
        max-width:335px;
        margin:0 20px;
        .title{
            font-size: 28px;
            line-height: 30px;
        }
        .txt {
            font-size: 15px;
            line-height: 24px;
            margin-top: 39px;
            margin-bottom: 22px;
        }
        .sendButton {
            // margin-top: 30px;
            margin-top: 15px;
            width: 100%;
            height: 50px;
            border-radius: 55px;
            box-sizing: border-box;
            font-size: 15px;
            line-height: 24px;

        }

    }
    
}

