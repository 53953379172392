
            @import "@/assets/css/vars.scss";
          






























































.wrappCrump{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--comments-background-color);
  color: var(--text-color);
  border-radius: 89px;
  width: fit-content;
  max-width: 100%;

  .crump{
      height: 50px;
      padding: 0 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      // border-radius: 20px;
      border-radius: 89px;
      cursor: pointer;
      text-transform: capitalize !important;

  }
  .active{
       background-color: var(--btn-color);
       color: var(--main-background-color);
  }
}

