
            @import "@/assets/css/vars.scss";
          























































.html-snippets-wrapper {
  text-align: left;
  .snippet-wrapper {
    margin-bottom: 20px;
    .title {
      color: var(--title-color);
      padding: 5px;
    }
    textarea {
      width: 100% !important;
      height: 100px !important;
      background-color: var(--main-background-color);
      color: var(--text-color);
    }
  }
}
