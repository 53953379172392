
            @import "@/assets/css/vars.scss";
          














































































































































































































































 @import '@/assets/css/singUp.scss';
.singinComponent{
        //  background-color:rgba(48, 219, 241, 0.609);
        // width: 406px;
        width: 384px;
        height: fit-content;
        background-color: var(--login-modal-background-color);
        padding: 72px 47px;
        box-sizing: border-box;
        .wrapp_top{
        display: flex;
        flex-direction: column;
        .logo{
            margin: auto;
        }
        .loginTitle {
            font-style: normal;
            font-weight: 700;
            font-size: 23px;
            line-height: 20px;
            text-align: center;
            color: var(--title-color);
            white-space: nowrap;
            margin-top: 13px;
            margin-bottom: 57px;
        }
    }
        .terms{
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 25px;
            /* identical to box height, or 208% */


            /* Gray 3 text */

            color: var(text-color);
            .bold{
               color: var(text-color);
               font-weight: bold; 
               text-decoration: none;
            }

        }
       
    }
@media only screen and (max-width: 500px){
    .singinComponent{
    //  background-color:rgba(48, 219, 241, 0.609);
        width: 334px;
        // height: 711px;
        // margin-bottom: 71px!important;
        height: fit-content;
        

        .singinTitle {
            font-size: 28px;
            line-height: 30px;
            margin-bottom: 60px;
        }
        .terms{
            padding-bottom: 120px!important;
        }
       
    }
}

