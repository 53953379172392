
            @import "@/assets/css/vars.scss";
          
















































.subComment{
       border-radius: 5px;
       box-sizing: border-box;
       width:100%;
       padding: 10px;
       padding-left: 0;
       padding-right: 17px;
       display: flex;
       justify-content: flex-start;
       align-items: center;

       .line{
        box-sizing: border-box;
        width: 50px;
        height: 0;
        border-top: 1px solid var(--comments-background-color);
       }
       .wrappData{
        width: calc(100% - 50px);
        padding-left: 22px;
       }
       .wrappMaindata{
         display: flex;
         justify-content: space-between;
          // margin-bottom: 10px;
          font-weight: bold;

          .name{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 17px;
            color: var(--text-color);
          }
          .date{
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            /* identical to box height, or 167% */

            letter-spacing: 0.04em;
            color: var(--text-soft-color);

          }
       }
}

