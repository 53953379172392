
            @import "@/assets/css/vars.scss";
          














































































































































































.wrappLogin{
    // display: inline-block;
    margin-right:20px;
    // margin-top: auto;
    position: relative;
    width: 35px;
    cursor: pointer;
    .loginImg{
        display: flex;
        height: 21px;
        // margin-top: auto;
        justify-content: space-between!important;
        // align-items:flex-end;
        // position: absolute;
        // bottom: -4px;
        width: 100%;
        .imgRoundWrapp{
            // position: absolute;
            // bottom: -2px;
            // left: 0;
            width: 21px;
            height:21px;
            .imgRound{
              object-fit: cover;
              border-radius: 50%;
              height: 100%;
              width: 100%;
            }
         
        }
        .arrowRoundWrapp{
        
            width: fit-content;
            height:20px;
            // padding-top: 2px;

        }
    }


    .loginContent{
        position: absolute;
        width: 154px;
        min-height: 55px;
        right: -10px;
        // top: 46px;
         top: 39px;
        z-index: 1000;
        background: var(--main-background-color);
        /* analytic container shadow */
        box-shadow: 0px 0px 5px var(--border-color);
        border-radius: 5px;
        padding: 6px 11px 7px 10px;
        .div{
            text-decoration: none;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 26px;
            display: flex;
            align-items: center;
            text-align: center;
            color: var(--text-color);
            box-sizing: border-box;
            //height: calc(50% - 7px);
            height: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .arrow {
                transform: rotate(-90deg);
            }
        }
        
    }
}
@media only screen and (max-width: 768px){
    .wrappLogin{
         .loginContent{
            right: 0px;
         }

    }
}

